import React from "react"

const ColorsSection = () => (
    <section id="Colors" className="wrapper guide">
      <div className="padded">
        <h1> Colors</h1>

        <div className="row wrapper spaced border">
          <div className="block25 card bg-red font-beige-o">red <p>Used for buttons with destructive actions and critical error notifications.</p></div>
          <div className="block25 card bg-yellow">yellow<p>Used for CTA Buttons and warning notifications.</p></div>
          <div className="block25 card bg-green font-beige-o">green<p>Used for action buttons success notifications.</p></div>
          <div className="block25 card bg-blue font-beige-o">blue<p>Used for informational notifications and as pick highlight.</p></div>
          <div className="block25 card bg-beige">beige<p>Used as main background and font color.</p></div>
          <div className="block25 card bg-light-gray font-gray">light grey<p>Used for disabled buttons.</p></div>
          <div className="block25 card bg-gray font-beige-o">grey <p>Used for standard buttons.</p></div>
          <div className="block25 card bg-dark-gray font-beige-o">dark grey <p>Used as secondary background and font color. Also used for shadows.</p></div>

        </div>
      </div>
    </section>
)

export default ColorsSection